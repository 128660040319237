import clsx from "clsx"
import { ENButtonGroup } from "en-react/dist/src/components/ButtonGroup"
import { ENDialog } from "en-react/dist/src/components/Dialog"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import ZtnaButton from "src/shared/components/ZtnaButton"
import CircularLoader from "../CicularLoader"
import { useModalStyles } from "./Modal.styles"
import { ModalProps } from "./Modal.types"

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  onCancel,
  onOk,
  onExport,
  children,
  title,
  onCancelProps,
  onExportProps,
  onOkProps,
  contentClass,
  useContentWrapperClass = true,
  showFooter = true,
  footerContent,
  width = 595,
  showBackIconButton = false,
  onBackIconClick,
  isWizard,
  portalId,
}) => {
  const {
    visible: cancelVisible = true,
    disabled: cancelDisabled,
    title: cancelTitle = "Cancel",
    onCancelOnly,
  } = onCancelProps || {}

  const { visible: exportVisible = true, title: exportTitle = "Export", onExportOnly } = onExportProps || {}

  const classes = useModalStyles()

  const [open, setOpen] = useState(true)
  const containerRef = useRef(portalId && (document.getElementById(portalId) as HTMLElement))

  useEffect(() => {
    if (portalId) containerRef.current = document.getElementById(portalId) as HTMLElement
  }, [])

  const Dialog = (
    <div className={classes.modalContainer}>
      {/* This `div` element can be removed in future if implemented within `en-react` library */}
      <ENDialog
        showBackIconButton={showBackIconButton}
        onBackButtonClick={onBackIconClick}
        heading={title.toString()}
        isActive={open}
        hasBackdrop
        // `handleOnKeydown` is being used to handle closing of modal on escape key
        handleOnKeydown={(event) => {
          if (event.key === "Escape") {
            setOpen(false)
            onCancel?.()
          }
        }}
        // `toggleActive` runs when clicked on cross icon
        toggleActive={() => onCancel?.()}
        disableClickOutside
        width={parseInt(`${width}`)} // We can remove this parseInt once no component is passing string value as width to <Modal />
        /* 
      The following props aren't working currently:
        // `open` runs only if we have a button inside Modal that triggers it
        open={() => console.log("opening modal")}
        // `handleOnClickOutside` not working at all
        handleOnClickOutside={() => console.log("clicked outside modal")}
        // `close` runs on every click outside modal
        close={() => console.log("closing modal")}
    */
      >
        <div className={clsx(useContentWrapperClass ? classes.contentWrapper : "", contentClass)}>{children}</div>
        {footerContent}
        <ENButtonGroup alignment="right" slot="footer" className={clsx({ [classes.hideFooter]: !showFooter })}>
          <>
            {onCancel && cancelVisible && (
              <ZtnaButton
                buttonType="secondary"
                dataTestid="modal-cancelButton"
                id="en-ztna-modal-cancelButton"
                disabled={cancelDisabled}
                onClick={() => {
                  onCancel()
                  onCancelOnly && onCancelOnly()
                }}
                title={cancelTitle}
              />
            )}
            {onExport && exportVisible && (
              <ZtnaButton
                buttonType="secondary"
                dataTestid="modal-exportButton"
                id="en-ztna-modal-exportButton"
                onClick={() => {
                  onExport()
                  onExportOnly && onExportOnly()
                }}
                title={exportTitle}
                disabled={!showFooter}
              />
            )}
            <ZtnaButton
              buttonType={onOkProps?.buttonType || "primary"}
              title={onOkProps?.title || "Ok"}
              dataTestid="modal-saveButton"
              id={onOkProps?.id || "en-ztna-modal-saveButton"}
              disabled={onOkProps?.disabled || onOkProps?.isLoading || !showFooter}
              variant="outlined"
              startIcon={onOkProps?.isLoading && <CircularLoader isDark />}
              onClick={onOk}
            />
          </>
        </ENButtonGroup>
      </ENDialog>
    </div>
  )

  if (portalId && containerRef.current) {
    return createPortal(Dialog, document.getElementById(portalId) as HTMLElement)
  }

  return Dialog
}

export default Modal
